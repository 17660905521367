<template>
  <v-container fluid>
    <presidencia-filtros></presidencia-filtros>
  </v-container>
</template>

<script>
import presidenciaFiltros from "../components/filtros/PresidenciaFiltros.vue";

export default {
  components: {
    presidenciaFiltros
  }
};
</script>
